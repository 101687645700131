//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import HeaderBalance from '@/components/Controls/HeaderBalance';
export default {
  name: 'WelcomePopup',
  components: {
    HeaderBalance: HeaderBalance
  },
  props: {
    isShowEmailNotify: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close: function close() {
      this.$store.ga.clickButton({
        location: 'how_it_works',
        buttonName: 'get_started'
      });
      this.$store.dispatch('modals/setWelcomePopup', {
        isOpen: false
      });
    }
  },
  mounted: function mounted() {
    this.$store.$cookies.set('showWelcomePopup', true, {
      expires: '365d',
      path: '/'
    });
  }
};